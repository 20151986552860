import React from "react";
import { email, required } from "../../../helpers/form-validation";
import { Row, Col, Button } from "react-bootstrap";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Select2 from 'react-select'
import "./quote-form.scss";
export const CreateEndUser = React.forwardRef(({ handleChange }, ref) => {
    return (
        <Form ref={ref}>
        <Row>
            <Col lg="4" className="mb-3">
                <Input
                    className="input form-control custom-input"
                    type="text"
                    name="firstName"
                    onChange={handleChange}
                    placeholder="First Name"
                />
            </Col>
            <Col lg="4" className="mb-3">
                <Input
                    className="input form-control custom-input"
                    type="text"
                    name="lastName"
                    onChange={handleChange}
                    placeholder="Last Name"
                />
            </Col>
            <Col lg="4" className="mb-3">
                <Input
                    validations={[required, email]}
                    className="input form-control custom-input"
                    type="text"
                    name="emailAddress"
                    onChange={handleChange}
                    placeholder="Email"
                />
            </Col>
            <Col lg="4" className="mb-3">
                <Input
                    className="input form-control custom-input"
                    type="text"
                    name="companyName"
                    onChange={handleChange}
                    placeholder="Company"
                />
            </Col>
            <Col lg="4" className="mb-3">
                <Input
                    className="input form-control custom-input"
                    type="text"
                    name="phoneNumber"
                    onChange={handleChange}
                    placeholder="Phone Number"
                />
            </Col>
            <Col lg="4" className="mb-3">
                <Input
                    validations={[required]}
                    className="input form-control custom-input"
                    type="text"
                    name="BillingAddress1"
                    onChange={handleChange}
                    placeholder="Billing Address 1"
                />
            </Col>
            <Col lg="4" className="mb-3">
                <Input
                    className="input form-control custom-input"
                    type="text"
                    name="BillingAddress2"
                    onChange={handleChange}
                    placeholder="Billing Address 2"
                />
            </Col>
            <Col lg="4" className="mb-3">
                <Input
                    validations={[required]}
                    className="input form-control custom-input"
                    type="text"
                    name="City"
                    onChange={handleChange}
                    placeholder="City"
                />
            </Col>
            <Col lg="4" className="mb-3">
                <Input
                    validations={[required]}
                    className="input form-control custom-input"
                    type="text"
                    name="PostalCode"
                    onChange={handleChange}
                    placeholder="Postal Code"
                />
            </Col>
            <Col lg="4" className="mb-3">
                <Input
                    validations={[required]}
                    className="input form-control custom-input"
                    type="text"
                    name="State"
                    onChange={handleChange}
                    placeholder="State"
                />
            </Col>
            <Col lg="4" className="mb-3">
                <Input
                    validations={[required]}
                    className="input form-control custom-input"
                    type="text"
                    name="Country"
                    onChange={handleChange}
                    placeholder="Country"
                />
            </Col>
        </Row>
    </Form>
    );
});