import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Routes from "./routes/Routes";
import store from './store';
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Notifications from 'react-notify-toast';
import { getToken, getUser } from "./helpers/utility";
import { TOKEN_COOKIE, PATH_COOKIE, USER_COOKIE, IS_LOGIN, LOGIN_RES } from "./helpers/constants";
import { connect } from 'react-redux';
import { BASE_URL } from "./api/constants";
import cookie from 'react-cookies'; // Make sure to import the cookie library
const INACTIVITY_TIMEOUT = 180000; // 30 minutes in milliseconds

//basic compnent of which holds all routes
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.inactivityTimer = null;// timer
    }
    resetInactivityTimer = () => {
        if (this.inactivityTimer) {
            clearTimeout(this.inactivityTimer);
        }
        this.inactivityTimer = setTimeout(() => {
            this.handleLogout();
        }, INACTIVITY_TIMEOUT);
    };

    handleLogout = () => {
        cookie.remove(TOKEN_COOKIE, { path: PATH_COOKIE });
        cookie.remove(USER_COOKIE, { path: PATH_COOKIE });
        store.dispatch({ type: IS_LOGIN, payload: false });
        window.location.pathname = '/external-quotes';
    };
    componentDidMount() {
        const token = getToken();
        this.resetInactivityTimer();
        window.addEventListener("mousemove", this.resetInactivityTimer);
        window.addEventListener("mousedown", this.resetInactivityTimer);
        window.addEventListener("touchstart", this.resetInactivityTimer);
        window.addEventListener("scroll", this.resetInactivityTimer);
        window.addEventListener("click", this.resetInactivityTimer);
        window.addEventListener("keypress", this.resetInactivityTimer);

        this.checkuserType();
        if (token) {
            store.dispatch({ type: IS_LOGIN, payload: true });
            store.dispatch({ type: LOGIN_RES, payload: getUser() || {} });
            // store.dispatch(GetProfileAction());
            // window.location = BASE_URL + '/login'
        }
    }
    componentDidUpdate() {
        this.resetInactivityTimer();
        this.checkuserType()
    }
    checkuserType(){
        const user = getUser();
        const token = getToken(); // Check if the user is logged in
        if (token && user && user.usertype !== 'external') {
            var next = window.location.href.split('login?');
            if (next.length === 2 && next[1].indexOf('next') > -1) {
                next = next[1].split('next=')[1];
                window.location = next;
            } else {
                window.location = '/order-search';
            }
        }
    }
    componentWillUnmount() {
        clearTimeout(this.inactivityTimer); 
        window.addEventListener("mousemove", this.resetInactivityTimer);
        window.addEventListener("mousedown", this.resetInactivityTimer);
        window.addEventListener("touchstart", this.resetInactivityTimer);
        window.addEventListener("scroll", this.resetInactivityTimer);
        window.addEventListener("click", this.resetInactivityTimer);
        window.addEventListener("keypress", this.resetInactivityTimer);
    }
    render() {
        return (
            <div id="all" history={this.props.history}>
                <Notifications options={{ zIndex: 9999999, top: '0px' }} />
                <Routes socket={this.socket} />
            </div>
        );
    }
}
const stateMap = (state) => {
    return {
        user: state.user.user,
    };
};
export default connect(stateMap)(withRouter(App));